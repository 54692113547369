import { Link } from 'gatsby'
import React from 'react'
import './style.scss'

const Footer = ({ author, title }) => (
  <div className="footer">
    <div className="container">
      <hr className="border-primary" />
      <p>
        Brofists 👊
        <a href="https://utkarsh.co" target="_blank">
          <br />
          <strong>{author}</strong>
        </a>
      </p>
    </div>
  </div>
)

export default Footer
